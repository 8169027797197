(function($){

  $(window).load(function() {
    var $container = $(".ms-container");
//    $container.hide();
//    $(".view-artworks .item-list").hide();

    $container.find(".ms-item").each(function(){
      $this = $(this);
      var random = Math.floor(Math.random() * 2) + 2;
      $this.addClass("ms-"+random);
    });
  //  var msc = $container.width();
    var msc = 39;
    var msg = 12;
    var ww = $(window).width();
    if(ww >= 400) {
      msc = 78;
      msg = 24;
    }

    $container.fadeIn(1000).masonry({ "columnWidth": msc, "gutter": msg, "itemSelector": ".ms-item"});
    $pager = $(".view-artworks .item-list");
    $pager.fadeIn(1000).find("a").prepend("<span class=\"icon\" />").on("click",function(){
      $pager.hide();
    });
  });

  Drupal.behaviors.viewsLoadMoreAppended = {
    attach: function(context, settings){
      if(typeof context.selector != "undefined") {
        var appended = [];
        $(context.selector).find(".ms-item")
          .each(function(){
          $this = $(this);
          if(!($this.hasClass("ms-2") || $this.hasClass("ms-3"))) {
            random = Math.floor(Math.random() * 2) + 2;
            $this.addClass("ms-"+random);
            appended.push(this);
          }
        });
        $(appended).hide();
        $(context.selector).imagesLoaded(function(){
          setTimeout(function(){
            $(appended).show();
            $(".ms-container").masonry("appended",$(appended));
            $pager = $(".view-artworks .item-list");
            $pager.fadeIn(1000).find("a").prepend("<span class=\"icon\" />").on("click",function(){
              $pager.hide();
            });
          }, 1000);
        });
      }
    }
  }
}(jQuery));